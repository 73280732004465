import Head from 'next/head';
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import { LanguageContextProvider } from "../context/languageContext";
import '../styles/globals.scss';

export default function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page)

  function stopLoading() {
    const loader = document.getElementById('globalLoader');
    if (loader) {
      loader.style.display = 'none'
    }
  }

  // Hide splash screen shen we are server side 
  useEffect(() => {
    if (typeof window !== 'undefined') {
      stopLoading()
    }
  }, []);

  return (
    <LanguageContextProvider>
      <Head>
        <title>Paretro</title>
        <meta name="description" content="Use modern theoretical frameworks to improve decision making, reduce waste and get better results in minutes." />
        <link rel="icon" type="image/ico" sizes="32x32" href="/images/favicon.ico" />
        <link rel="apple-touch-icon" type="image/png" href="logo192.png" />

        <meta name='Optimize decision making with data' content='Paretro logistics landing page'></meta>
      </Head>
      <div id='globalLoader'>
        <img
          src={'https://firebasestorage.googleapis.com/v0/b/tixynft-33400.appspot.com/o/loadingImage.svg?alt=media&token=2e08f6a4-98a4-4375-bfc4-bf38d39e63e3'} alt='loading image'
        ></img>
      </div>
      {loading ?
        <div id='globalLoader'>
          <img
            src={'https://firebasestorage.googleapis.com/v0/b/tixynft-33400.appspot.com/o/loadingImage.svg?alt=media&token=2e08f6a4-98a4-4375-bfc4-bf38d39e63e3'} alt='loading image'
          ></img>
        </div>
        :
        getLayout(<Component {...pageProps} /> )
      }
    </LanguageContextProvider>
  )
}
