import { createContext, useState } from 'react';

const LanguageContext = createContext({
  language: 'eng',
  handleLanguage: () => { },
})

export const LanguageContextProvider = ({ children }) => {
  const [language, setlanguage] = useState('swe');

  const handleLanguage = (newLanguage) => {
    if (newLanguage != null) { 
      setlanguage(newLanguage);
    }
  };
  
  const context = { language, handleLanguage }

  return (
    <LanguageContext.Provider value={context} >
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext